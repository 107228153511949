<template>
  <div>
    <h4>Desde esta sección puedes borrar tu cuenta de nuestro sistema</h4>
		<h5>Perderás las evaluaciones realizadas</h5>
		<p>¿Estás seguro que quieres borrar tu cuenta?.</p>
		<CButton color="primary" class="px-4" @click="deleteAccount">Borrar mi cuenta</CButton>
  </div>
</template>

<script>
import { deleteUser } from '@/helpers/api/user'

export default {
  name: 'DeleteAccount',
  components: {
  },
  data () {
    return {
    }
  },
	mounted(){
	},
  methods: {
		async deleteAccount(){
			await deleteUser()
      localStorage.removeItem('userToken')
      this.$store.commit('setIsAuthenticated', false)
      this.$router.push({name: 'Login'})
		}
  }
}
</script>
